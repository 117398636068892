const ordersTypes = {
    SAVE_ORDER_HISTORY_START: 'SAVE_ORDER_HISTORY_START',
    GET_USER_ORDER_HISTORY: 'GET_USER_ORDER_HISTORY',
    SET_USER_ORDER_HISTORY: 'SET_USER_ORDER_HISTORY',
    GET_ORDER_DETAILS_START: 'GET_ORDER_DETAILS_START',
    SET_ORDER_DETAILS: 'SET_ORDER_DETAILS',
    GET_ORDER_HISTORY: 'GET_ORDER_HISTORY',
    SET_ORDER_HISTORY: 'SET_ORDER_HISTORY'
};

export default ordersTypes;
