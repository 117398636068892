export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "green-mountain-synergy.firebaseapp.com",
    projectId: "green-mountain-synergy",
    storageBucket: "green-mountain-synergy.appspot.com",
    messagingSenderId: "29419361152",
    appId: "1:29419361152:web:e6ec1e516c9e65806f2765",
    measurementId: "G-X9ZGVP4H5C"
  };

